import { required, maxLength } from 'vuelidate/lib/validators';

export default () => {
  return {
    TpCargoId: { required },
    ClassificationName: { required, maxLength: maxLength(150) },
    FgApplyWeight: { required },
    Operator: { required },
    Weight: { required },
    Status: { required },
  };
};
