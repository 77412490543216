<template>
    <main>
      <CModalExtended
        :title="title"
        color="dark"
        :show.sync="modalActive"
        @update:show="closeModal($event)"
        size="lg"
        :closeOnBackdrop="false"
      >
        <loading-overlay
          :active="Loading"
          :is-full-page="true"
          loader="bars"
        />
        <CRow class="justify-content-between">
          <CCol sm="10">
            <CSelect
              :label="$t('label.cargoGroup')"
              addLabelClasses="text-right required"
              :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-6'}"
              :options="computedTpGpoGeneral"
              :value="TpCargoId"
              @change="gpoSelectedFilter($event)"
              :is-valid="hasError($v.TpCargoId)"
              :invalid-feedback="errorMessage($v.TpCargoId)"
              v-model.trim="$v.TpCargoId.$model"
            />
          </CCol>
          <CCol sm="2" class="d-flex align-items-center justify-content-end mb-3">
            <CButton v-if="!collapse"
              square 
              color="add" 
              @click="toggleCollapse"
              v-c-tooltip="{
                content: $t('label.commodity'),
                placement: 'top-end',
              }"
            >
              <CIcon name="cil-playlist-add" />
              <span class="ml-1">
                {{$t('label.nueva')}}
              </span>
            </CButton>
            <CButton v-else
              square 
              color="add" 
              @click="closeCollapse"
              v-c-tooltip="{
                content:  $t('label.commodity'),
                placement: 'top-end',
              }"
              >
              <CIcon name="cil-playlist-add" />
              <span class="ml-1">
                {{$t('label.nueva')}}
              </span>
            </CButton>
          </CCol>
         
          <CCol sm="12">
              <collpaseCommodity
                :modal="modalCollapse" 
                :collapse.sync="collapse" 
                @close-collapse="closeCollapse" 
                @refresh-Commodity="getTpCargoGeneral"
              />
          </CCol>
        </CRow>
  
        <dataTableExtended
          class="align-center-row-datatable"
          size="lg"
          :fields="fields"
          :items="computedTpGeneralCommodity"
          :items-per-page="5"
          column-filter
          pagination
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
        >
          <template #checked="{item}">
            <td>
              <CInputCheckbox
                custom
                class="checkbook-filter ml-1"
                :checked="item.checked"
                @update:checked="addRemoveId(item)"
              />
            </td>
          </template>
          <template #checked-filter>
            <td style="border-top:none" class="text-center">
              <CInputCheckbox
                custom
                :label="$t('label.all')"
                class="checkbook-filter mr-2"
                :checked="AllCommodities"
                @update:checked="SelectAllCommodities($event)"
              />
            </td>
          </template>
          <template #options="{ item }">
            <td class="center-cell">
              <CButton
                square
                color="edit"
                size="sm"
                v-c-tooltip="{
                  content: $t('label.edit')+' '+$t('label.commodity'),
                  placement: 'top-end',
                }"
                @click="showCollpaseEditCommodity(item)"
              >
                <CIcon name="pencil" />
              </CButton>
            </td>
          </template>
          
        </dataTableExtended>
        <p v-if="!isEdit && arrIds.length === 0" class="mt-2 text-danger">
          {{$t('label.must')+' '+$t('label.toSelect')+' '+$t('label.atLeast')+' 1 '+$t('label.type')+' '+$t('label.of')+' '+$t('label.commodity')}}
        </p>
        <template #footer>
          <CButton
            outline
            color="add"
            @click.stop="isEdit?statusConfirmation(true, Status, submit) :submit()"
          >
            <CIcon name="checkAlt" />&nbsp; {{$t('button.accept')}}
          </CButton>
          <CButton color="wipe" @click="closeModal(false)">
            <CIcon name="x" />&nbsp; {{$t('button.cancel')}}
          </CButton>
        </template>
      </CModalExtended>
    </main>
  </template>
  <script>
  import GeneralMixin from '@/_mixins/general';
  import ModalMixin from '@/_mixins/modal';
  import { DateFormater } from '@/_helpers/funciones';
  import CargaGranel from '@/_mixins/carga-granel';
  import embalajeValidations from '@/_validations/carga-general/embalajeValidations';
  import UpperCase from '@/_validations/uppercase-directive';
  import collpaseCommodity from '../../rubro/mercancia-collapse';

  
  function data() {
    return {
      modalActive: false,
      Loading: false,
      itemsPackagingList: [],
      itemGpoEmbalajes: [],
      itemPackagingChecked: [],
      itemPackagingCheckedUpdated: [],
      itemsGposCommodity: [],
      TpCargoId: '',
      selectAll: false,
      resetSelectAll: false,
      Status: 0,
      collapse: false,
      commoditySelected: '',
      commodityEdit:{},
      itemsCommodityList: [],
      arrIds: [],
      arrIdsOrigin: [],
      AllCommodities: false,
      modalCollapse: '',
    };
  }
  
  //methods
  function SelectAllCommodities(event) {
    this.arrIds = [];
    if (event) {
      this.AllCommodities = true;
      //SELECCIONA TODOS LOS ITEM
      this.itemsCommodityList.map((item) => {
        this.arrIds.push({CommodityId: item.CommodityId, TpCargoCommodityId: item.TpCargoCommodityId ? item.TpCargoCommodityId : '' , Status: 1});  
      });

    }else{
      this.AllCommodities = false;
    }
  }

  function addRemoveId(item) {
    let bool = this.arrIds.filter((e) => e.CommodityId == item.CommodityId)

    if( bool.length == 0 ){
      //ADD
      this.arrIds.push({CommodityId: item.CommodityId, TpCargoCommodityId: '', Status: 1});
    }
    else{
      //DELETE
      this.arrIds = this.arrIds.filter((e) => e.CommodityId !== item.CommodityId);
    }

    if (this.arrIds.length == this.itemsCommodityList.length) {
      this.AllCommodities = true;
    }else{
      this.AllCommodities = false;
    }
  }

  function gpoSelectedFilter(event) {
    this.TpCargoId = event.target.value;
    this.arrIds = [];
    this.arrIdsOrigin = [];
    this.getTpGeneralCommodity()
  }

  async function getTpCargoGeneral() {
    this.Loading = true;
    await this.$http
      .get('TpGeneralCargo-list')
       .then(async response => {
        let list = response.data.data;
        if (list.length != 0) {
          this.itemsGposCommodity = list;
          this.TpCargoId = this.isEdit ? this.tpcargoSelcted : this.TpCargoId = list[0].TpCargoId;
        }
        await this.getTpGeneralCommodity()
        await this.getHeadingList();
        this.Loading = false;
      })
      .catch((e) => {
      this.Loading = false;
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
      });
    }) 
  }

  async function getTpGeneralCommodity() {
    if (this.TpCargoId != '') {
      this.Loading = true;
      await this.$http
      .get('TpCargoGeneralCommodity-list',{TpCargoId: this.TpCargoId})
      .then((response) => {
        let list = response.data.data;
        if (list && list.length != 0){
          this.itemsCommodityList = list;
          this.itemsCommodityList.map(item =>{
            if (item.TpCargoCommodityId) {
              this.arrIdsOrigin.push({CommodityId: item.CommodityId, TpCargoCommodityId: item.TpCargoCommodityId});
            }
          })
        }
        list.map((item) => {
          if ( item.TpCargoCommodityId !== null ) {
            this.arrIds.push({CommodityId: item.CommodityId, TpCargoCommodityId: item.TpCargoCommodityId});
          }
        });
        if (this.arrIds.length == this.itemsCommodityList.length) {
          this.AllPackaging = true;
        }else{
          this.AllPackaging = false;
        }
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      }); 
    }else{
      this.itemsCommodityList = [];
      this.arrIdsOrigin = [];
    }
  }
  
  function toggleCollapse(){
    this.collapse = true;
    this.modalCollapse = {Item: this.collapse, Rubros: this.itemsHeiding };
  }

  function showCollpaseEditCommodity(item){
    this.collapse = true;
    this.commoditySelected = item.CommodityId;
    this.modalCollapse = {Item: item, Rubros: this.itemsHeiding };
  }

  function closeCollapse(){
    this.collapse = false;
    this.modalCollapse = null;
  }

  async function getHeadingList() {
    await this.$http
    .get('Heading-list',{ filter: 'ALL' })
      .then((response) => (
          this.itemsHeiding = response.data.data
      ))
  }
    
  //### Function para cerrar el modal ###
  function closeModal(event) {
    this.commoditySelected = '';
    this.collapse = false;
    this.modalCollapse = null;
    this.modalActive = false;
    this.resetForm();
    this.$emit('updated-modal', event);
  }

  function resetForm(){
    this.TpCargoId = '';
    this.itemsCommodityList = [],
    this.arrIds = [],
    this.arrIdsOrigin = [],
    this.AllCommodities = false
    this.$v.$reset();
  }
  
  async function submit() {
    try {
      this.Loading = true;
      let Json = [];
      let Total_Id = [];

      if (this.arrIds.length === 0 && !this.isEdit) {
        this.Loading = false;
        throw this.$t('label.errorsPleaseCheck');
      }

    Total_Id = [...this.arrIdsOrigin, ...this.arrIds];
    
  
    Total_Id.map(item =>{
      if (!Json.some(e => e.CommodityId == item.CommodityId )) {
        Json.push(item);
      }
    })

    let TpCargoCommodityJson = Json.map((item) => {
      let ValidateCurrentCargo = this.arrIds.some(e =>e.CommodityId == item.CommodityId)
        return {
          TpCargoCommodityId: item.TpCargoCommodityId ? item.TpCargoCommodityId : '',
          TpCargoId: this.TpCargoId,
          CommodityId: item.CommodityId,
          Status: ValidateCurrentCargo ? 1 : 0,
        };
    })

      await this.$http.post("TpCargoGeneralCommodity-insert", TpCargoCommodityJson, { root: 'TpCargoCommodityJson' })
        .then((response) => {
          if (response.status === (200 || 201)) {
            const messageSuccess = response.data.data[0].Response;
            this.$emit('refresh-data-table');
            this.Loading = false;
            this.closeModal(false);
            this.notifySuccess({ text: messageSuccess });
          }
        })
        .catch((e) => {
          this.Loading = false;
          this.notifyError({ text: e });
        });
    } catch (e) {
      this.Loading = false;
      this.notifyError({ text: e });
    }
  }

  //computed
  function computedTpGpoGeneral(){
    if(this.itemsGposCommodity.length === 0){
        return [{
        value: '', 
        label: this.$t('label.select'),
        }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.select'),
      }]
      this.itemsGposCommodity.map((e)=>{
        chart.push({
            label: e.TpCargoName,
            value: e.TpCargoId,
        })    
      })
      return chart;
    }
  }
 
  function fields(){
    return [
        { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center', },
        { key: 'TpCargoName', label: this.$t('label.group'),  _style:'width:20%;',_classes:"center-cell text-center"},
        { key: 'CommodityNameEs', label: `${this.$t('label.commodity')} (ES)`, _style:'width:20%;',  _classes:"center-cell text-center"},
        { key: 'CommodityNameEn', label: `${this.$t('label.commodity')} (EN)`, _style:'width:20%;',  _classes:"center-cell text-center"},
        { key: 'checked', label: '',_classes: 'center-cell', _style: 'width: 15%;text-align:center;', sorter: false, filter: false},
        { key: 'options', label: '', _style: 'min-width: 50px; width:5%', sorter: false, filter: false,},
      ];
  }

  function computedTpGeneralCommodity() {
    if (this.itemsCommodityList.length > 0) {
      return this.itemsCommodityList.map((item) => {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        let checkeds = this.arrIds.filter((e) => e.CommodityId == item.CommodityId);
        return {
          ...item,
          TpCargoName: item['TpCargoName'+_lang] ?? 'N/A',
          CommodityNameEs: item.CommodityNameEs ?? 'N/A',
          CommodityNameEn: item.CommodityNameEn ?? 'N/A',
          FormatedDate: item.TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
            : 'N/A',
          _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
          checked: (checkeds.length > 0),
        };
      });
    }
  }

  function title(){
    return this.$t('label.associateGeneralGroupCargoCommodity')
  }

  export default {
    name: 'modal-commodity',
    data,
    mixins: [
      GeneralMixin, 
      CargaGranel,
      ModalMixin
    ],
    props: {
      modal: null,
      tpcargoSelcted: String,
      isEdit: Boolean
    },
    validations: embalajeValidations,
    directives: UpperCase,
    components: {
      collpaseCommodity
    },
    methods: {
      SelectAllCommodities,
      addRemoveId,
      gpoSelectedFilter,
      getTpCargoGeneral,
      getTpGeneralCommodity,
      closeModal,
      submit,
      resetForm,
      showCollpaseEditCommodity,
      closeCollapse,
      getHeadingList,
      toggleCollapse
    },
    computed: {
      computedTpGpoGeneral,
      computedTpGeneralCommodity,
      fields,
      title,
    },
    watch: {
      modal: async function(val) {
        this.modalActive = val;
        if(val){
          this.$v.$touch();
          await this.getTpCargoGeneral();
        }
      },
      tpcargoSelcted: function(val){
        if (val) {
          this.isEdit = true;
          this.TpCargoId = val;
        }else{
          this.isEdit = false;
        }
      }
    },
    
  };
  </script>
  <style lang="scss">
  .center-cell {
    text-align: center;
  }
   
  </style>
  