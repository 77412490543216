var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 mt-3"},[_c('loading-overlay',{attrs:{"active":_vm.Loading,"is-full-page":true,"loader":"bars"}}),_c('CRow',[_c('CCol',{staticClass:"justify-content-start py-2",attrs:{"sm":"4"}},[_c('CSelect',{attrs:{"label":_vm.$t('label.group'),"addLabelClasses":"text-right","horizontal":{label:'col-auto', input:'col-sm-7'},"options":_vm.computedTpGpoGeneral,"value":_vm.valueFilterGpoSelected,"size":"sm"},on:{"change":function($event){return _vm.gpoSelectedFilter($event)}}})],1),_c('CCol',{staticClass:"d-flex justify-content-end py-2",attrs:{"sm":"8"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
          content: _vm.$t('label.classification'),
          placement: 'top-end',
        }),expression:"{\n          content: $t('label.classification'),\n          placement: 'top-end',\n        }"}],staticClass:"d-flex align-items-center",attrs:{"square":"","color":"add"},on:{"click":_vm.toggleShowModalCreateState}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1)],1),_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedTpGeneralCLasification,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: _vm.$t('label.edit')+' '+_vm.$t('label.classification'),
            placement: 'top-end',
          }),expression:"{\n            content: $t('label.edit')+' '+$t('label.classification'),\n            placement: 'top-end',\n          }"}],attrs:{"square":"","color":"edit","size":"sm"},on:{"click":function($event){return _vm.toggleShowModalEditGpo(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}])}),_c('modalClasification',{attrs:{"modal":_vm.showModal,"TpCargoSelected":_vm.TpCargoSelected,"title":_vm.titleModal,"editModal":_vm.editModal},on:{"update:modal":function($event){_vm.showModal=$event},"updated-modal":_vm.updateShowModalCreate,"refresh-data-table":_vm.mountedTpGeneralClasification}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }