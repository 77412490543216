<template>
  <CCard>
    <CCardHeader class="text-center bg-dark text-white">
      <b>{{$t('label.generalCargoList')}}</b>
    </CCardHeader>
    <CCardBody>
      <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
        <CustomTab :title="$t('label.generalCargoGroups')">
          <grupoCargaGeneral :Tab="Tab"/>
        </CustomTab>
        <CustomTab :title="$t('label.classifications')">
          <clasificacionCargaGeneral :Tab="Tab"/>
        </CustomTab>
        <CustomTab :title="$t('label.packaging')">
          <embalajeCargaGeneral :Tab="Tab"/>
        </CustomTab>
        <CustomTab :title="$t('label.commodity')">
          <Commodity  :Tab="Tab"/>
        </CustomTab>
      </CustomTabs>
    </CCardBody>
  </CCard>
</template>
<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import embalajeCargaGeneral from './embalaje/index-embalaje';
import grupoCargaGeneral from './grupos/index-grupo';
import Commodity from './commodity/index-commodity';
import clasificacionCargaGeneral from './clasificacion/index-clasificacion';



function handleTab(tab) {
  this.Tab = tab;
}

function data() {
  return {
    Tab: 0,
  };
}

export default {
  name: 'cargaGranel',
  data,
  components: {
    CustomTabs,
    CustomTab,
    embalajeCargaGeneral,
    grupoCargaGeneral,
    Commodity,
    clasificacionCargaGeneral,
  },

  methods:{
    handleTab,
  },
};
</script>
<style lang=""></style>
