<template>
    <div class="mb-3 mt-3">
      <loading-overlay :active="Loading" :is-full-page="true" loader="bars"/>
      <CRow>
          <CCol sm="4" class="justify-content-start py-2">
            <CSelect
              :label="$t('label.group')"
              addLabelClasses="text-right"
              :horizontal="{label:'col-auto', input:'col-sm-7'}"
              :options="computedTpGpoGeneral"
              @change="gpoSelectedFilter($event)"
              :value="TpCargoId"
              size="sm"
            />
        </CCol>
        <CCol sm="8" class="d-flex justify-content-end py-2">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="toggleShowModalCreateState"
          >
            <CIcon name="cil-playlist-add" />
            <span class="ml-1">
              {{$t('label.associateCommodity')}}
            </span>
          </CButton>
        </CCol>
      </CRow>
      <dataTableExtended
       class="align-center-row-datatable"
        size="lg"
        :fields="fields"
        :items="computedTpGeneralCommodity"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
      >
        <template #Status="{ item }">
          <td class="center-cell">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
  
        <template #options="{ item }">
          <td class="center-cell">
            <CButton
              square
              color="edit"
              size="sm"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.commodity'),
                placement: 'top-end',
              }"
              @click="toggleShowModalEditBulkCargo(item.TpCargoId)"
            >
              <CIcon name="pencil"/>
            </CButton>
          </td>
        </template>
      </dataTableExtended>
  
      <modalCommodity
        :title="titleModal"
        :modal.sync="showModal"
        :isEdit="isEdit"
        @close= "closeModal"
        @updated-modal="updateShowModalCreate"
        @refresh-data-table="getTpGeneralCommodity"
        :tpcargoSelcted="tpcargoSelcted"
      /> 
    </div>
  </template>
  <script>
  import { DateFormater } from '@/_helpers/funciones';
  import GeneralMixin from '@/_mixins/general';
  import modalCommodity from './modal-commodity';
  
  function data() {
    return {
      itemsCommodity: [],
      itemsGposCommodity: [],
      TpCargoId: '',
      showModal: false,
      titleModal: '',
      isEdit: false,
      tpcargoSelcted: '',
      Loading: false
    };
  }
  
  //methods
  async function getTpCargoGeneral() {
    await this.$http
      .get('TpGeneralCargo-list')
      .then(response => {
          let list = response.data.data;
          if (list.length != 0) {
              this.itemsGposCommodity = list;
          }
      })
      .catch((e) => {
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
      });
    }) 
  }

  function gpoSelectedFilter(event) {
    this.TpCargoId = event.target.value;
    this.getTpGeneralCommodity()
  }
  
  async function getTpGeneralCommodity() {
    this.Loading = true;
    await this.$http
      .get('TpCargoGeneralCommoditylist-by-TpCargoId',{TpCargoId: this.TpCargoId})
      .then((response) => {
        this.itemsCommodity = response.data.data;
        this.Loading = false;
      })
      .finally(() => {
        this.Loading = false;
      })
      .catch((e) => {
        this.Loading = false;
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: e,
          type: 'error',
        });
      });
  }

  //## Activa el modal para crear nuevo Estado
  function toggleShowModalCreateState() {
    this.showModal = true;
    this.isEdit = false;
    this.titleModal = this.$t('label.associateGeneralGroupCargoCommodity');
  }
  
  function toggleShowModalEditBulkCargo(id) {
    this.tpcargoSelcted = id;
    this.showModal = true;
    this.titleModal = this.$t('label.edit')+' '+this.$t('label.associateGeneralGroupCargoCommodity');
    this.isEdit = true;
  }
  
  //### ACtualiza el estado una vez cerrado el modal
  function updateShowModalCreate(event) {
    this.showModal = false;
    this.titleModal = '';
    this.isEdit = false;
    this.tpcargoSelcted = '';
  }

  function closeModal(){
    this.showModal = false;
    this.isEdit = false;
  }

  //computed
  function fields(){
    return [
        { label: '#', key: 'Nro', filter: false, _style: 'width:3%; text-align:center', },
        { key: 'TpCargoName', label: this.$t('label.group'),  _style:'width:20%;',_classes:"center-cell text-center"},
        { key: 'CommodityNameEs', label: `${this.$t('label.commodity')} (ES)`, _style:'width:20%;',  _classes:"center-cell text-center"},
        { key: 'CommodityNameEn', label: `${this.$t('label.commodity')} (EN)`, _style:'width:20%;',  _classes:"center-cell text-center"},
        { key: 'TransaLogin', label: this.$t('label.user'), _style:'width:10%;',  _classes:"center-cell text-center" },
        { key: 'FormatedDate', label: this.$t('label.date'), _style:'width:10%;', _classes:"center-cell text-center"},
        { key: 'Status', label: this.$t('label.status'),  _style:'width:10%; text-align:center;', _classes:"center-cell text-center" },
        { key: 'options', label: '', _style: 'min-width: 50px; width:5%', sorter: false, filter: false,},
      ];
  }

  //### Funcion computed para formatear los datos de la tabla ###
  function computedTpGeneralCommodity() {
    if (this.itemsCommodity.length > 0) {
      return this.itemsCommodity.map((item) => {
        let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
        return {
          ...item,
          TpCargoName: item['TpCargoName'+_lang] ?? 'N/A',
          CommodityNameEs: item.CommodityNameEs ?? 'N/A',
          CommodityNameEn: item.CommodityNameEn ?? 'N/A',
          FormatedDate: item.TransaRegDate
            ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate)
            : 'N/A',
          _classes: item.Status != 'ACTIVO' ? 'table-danger' : '',
        };
      });
    }
  }
  function computedTpGpoGeneral(){
    if(this.itemsGposCommodity.length === 0){
        return [{
        value: '', 
        label: this.$t('label.all'),
        }];
    }else{
      var chart = [{
        value: '', 
        label: this.$t('label.all'),
      }]
      this.itemsGposCommodity.map((e)=>{
        chart.push({
            label: e.TpCargoName,
            value: e.TpCargoId,
        })    
      })
      return chart;
    }
  }
  
  export default {
    name: 'index-commodity',
    data,
    props:{Tab: Number},
    computed: {
      computedTpGeneralCommodity,
      computedTpGpoGeneral,
      fields,
    },
    mixins: [GeneralMixin],
    methods: {
      gpoSelectedFilter,
      getTpCargoGeneral,
      updateShowModalCreate,
      closeModal,
      toggleShowModalCreateState,
      getTpGeneralCommodity,
      toggleShowModalEditBulkCargo,
    },
    components: {
      modalCommodity,
    },
    watch:{
      Tab: async function (val){
        if(val == 3){
          this.Loading = true;
          await this.getTpCargoGeneral();
          await this.getTpGeneralCommodity();
        }
      }
    },
  };
  </script>
  <style lang="scss">
  .center-cell {
    text-align: center;
  }
   
  </style>
  